import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ImageContentCta from '../components/ImageContentCta';
import ModularBlocks from '../components/ModularBlocks';

const ServicesPage = () => {
  const {
    datoCmsServicesListing: { seoMetaTags, title, bannerText, modularBlocks },
    allDatoCmsService: { services },
  } = useStaticQuery(graphql`
    query ServicesPageQuery {
      datoCmsServicesListing {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        modularBlocks {
          ...ContentModularBlockFragment
          ...ImageContentModularBlockV1Fragment
          ...TestimonialModularBlockFragment
          ...TextCtaModularBlockFragment
        }
      }
      allDatoCmsService(sort: { fields: position, order: ASC }) {
        services: nodes {
          id
          heading: title
          slug
          text: bannerText
          image: featuredImage {
            ...ImageContentCtaImageFragment
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} />
        {services.map(({ id, heading, text, image, slug }, i) => {
          const urlPath = `services/${slug}`;

          return (
            <ImageContentCta
              key={id}
              heading={heading}
              text={text}
              image={image}
              link={{ page: { slug: urlPath } }}
              alt={i % 2}
            />
          );
        })}
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default ServicesPage;
